























































































import { Messages } from "@/models/enums/messages.enum";
import Vue from "vue";
export default Vue.extend({
  name: "WorkOrder",
  data() {
    return {
      form: this.$form.createForm(this, { name: 'workorder' }),
      columnTable: [
        {
          title: "Date",
          dateIndex: "date",
          key: "date",
          sorter: false,
          width: "20%",
          scopedSlots: { customRender: "isNull" }
        },
        {
          title: "Unit Code",
          dateIndex: "unitCode",
          key: "unitCode",
          sorter: false,
          width: "20%",
          scopedSlots: { customRender: "isNull" }
        },
        {
          title: "Item Code",
          dateIndex: "itemCode",
          key: "itemCode",
          sorter: false,
          width: "20%",
          scopedSlots: { customRender: "isNull" }
        },
        {
          title: "Item Name",
          dateIndex: "itemName",
          key: "itemName",
          sorter: false,
          width: "20%",
          scopedSlots: { customRender: "isNull" }
        },
        {
          title: "Qty",
          dateIndex: "qty",
          key: "qty",
          sorter: false,
          width: "20%",
          scopedSlots: { customRender: "isNull" }
        },
      ],
      formRules: {
        dateFrom: {
          label: "Date From",
          name: "dateFrom",
          placeholder: "Insert Date",
          wrapperCol: {span: 5, offset: 0},
          decorator: ["dateFrom", {
            rules: [
              {
                required: true,
                message: Messages.VALIDATION_REQUIRED_ERROR
              }
            ]
          }]
        },
        dateTo: {
          label: "Date To",
          name: "dateTo",
          placeholder: "Insert Date",
          wrapperCol: {span: 5, offset: 0},
          decorator: ["dateTo", {
            rules: [
              {
                required: true,
                message: Messages.VALIDATION_REQUIRED_ERROR
              }
            ]
          }]
        },
        unitCode: {
          label: "Unit Code",
          name: "unitCode",
          placeholder: "Select Unit Code",
          wrapperCol: {span: 5, offset: 0},
          decorator: ["unitCode", {
            rules: [
              {
                required: true,
                message: Messages.VALIDATION_REQUIRED_ERROR
              }
            ]
          }]
        },
      },
      pagination: {
        limit: 10,
        size: 10,
        search: ""
      }
    }
  },
  methods: {
    onSubmit(): void {
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("values", values);
        }
      });
    },
    onPageSize(pagesize): void {
      this.pagination.size = pagesize.size;
    }
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
      }
    },
  },
});
