var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-content",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c(
                "a-card",
                { attrs: { title: "Report Work Order" } },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        [
                          _c(
                            "a-form",
                            _vm._b(
                              {
                                attrs: {
                                  layout: "horizontal",
                                  form: _vm.form,
                                  labelAlign: "left"
                                }
                              },
                              "a-form",
                              _vm.formItemLayout,
                              false
                            ),
                            [
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.formRules.dateFrom.label,
                                    "wrapper-col":
                                      _vm.formRules.dateFrom.wrapperCol
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.dateFrom.decorator,
                                        expression:
                                          "formRules.dateFrom.decorator"
                                      }
                                    ],
                                    attrs: {
                                      placeholder:
                                        _vm.formRules.dateFrom.placeholder
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.formRules.dateTo.label,
                                    "wrapper-col":
                                      _vm.formRules.dateTo.wrapperCol
                                  }
                                },
                                [
                                  _c("a-date-picker", {
                                    directives: [
                                      {
                                        name: "decorator",
                                        rawName: "v-decorator",
                                        value: _vm.formRules.dateTo.decorator,
                                        expression: "formRules.dateTo.decorator"
                                      }
                                    ],
                                    attrs: {
                                      placeholder:
                                        _vm.formRules.dateTo.placeholder
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    label: _vm.formRules.unitCode.label,
                                    "wrapper-col":
                                      _vm.formRules.unitCode.wrapperCol
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRules.unitCode.decorator,
                                          expression:
                                            "formRules.unitCode.decorator"
                                        }
                                      ],
                                      attrs: {
                                        placeholder:
                                          _vm.formRules.unitCode.placeholder
                                      }
                                    },
                                    [
                                      _c(
                                        "a-select-option",
                                        { attrs: { value: "1" } },
                                        [_vm._v(" Option 1 ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "a-form-item",
                                {
                                  attrs: {
                                    "wrapper-col": { span: 9, offset: 0 }
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      staticClass: "w-100",
                                      attrs: { type: "primary" },
                                      on: { click: _vm.onSubmit }
                                    },
                                    [_vm._v(" Find ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c("TableCustom", {
                            attrs: {
                              idtable: "table1",
                              onSelectChange: null,
                              selectedRowKeys: null,
                              dataSource: [],
                              columns: _vm.columnTable,
                              paginationcustom: true,
                              defaultPagination: false
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c("Pagination", {
                            attrs: {
                              total: _vm.pagination.limit,
                              pageSizeSet: _vm.pagination.size,
                              idPagination: "pagination1"
                            },
                            on: {
                              "response-pagesize-change": function(val) {
                                return _vm.onPageSize(val)
                              },
                              "response-currentpage-change": function() {}
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { staticClass: "text-right", attrs: { span: 12 } },
                        [
                          _c(
                            "a-space",
                            [
                              _c("a-button", { attrs: { type: "primary" } }, [
                                _vm._v("Download")
                              ]),
                              _c("a-button", { attrs: { type: "primary" } }, [
                                _vm._v("Print")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }